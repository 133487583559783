:root{
  --mining-priority-1:#70AD47;
  --mining-priority-2:#C6E0B4;
  --mining-priority-3:#FFC000;
  --mining-priority-4:#FF0000;
  --mining-yield: #fff;
  --mining-profit: #5B9BD5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  min-width: 575px;
  background-color: #011728;
}
 
.mining-priority-1{
  background-color: var(--mining-priority-1);
}
.mining-priority-2{
  background-color: var(--mining-priority-2);
}
.mining-priority-3{
  background-color: var(--mining-priority-3);
}
.mining-priority-4{
  background-color: var(--mining-priority-4);
}

.mining-yield{
  background-color: var(--mining-yield);  
  width:300px;
}

.mining-input{
  position:relative;
  background:white;
}

.mining-input input{
    border: 0;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    font-size: calc(10px + 2vmin);
    text-align: center;
}

.mining-input input:focus,.mining-input input:focus-visible{
  border:0;
  outline:0;
}

.mining-profit-calculated{
  background-color: var(--mining-profit);
  width:300px;
}

.mining-total-refining-cost{
  background-color: var(--mining-priority-4);
}

.text-right{
  text-align: right;
}

.mining-total-profit{
  background-color: var(--mining-profit);
  position: sticky;
  bottom: 0;
}

table{  
  color:black;
  position:relative;
}

thead{
  color:white;
}

@media only screen and (max-width: 1140px) {
  
  
  thead tr th:nth-child(5), thead tr th:nth-child(4), 
  tbody tr td:nth-child(5), tbody tr td:nth-child(4),
  tfoot tr td:nth-child(2)
  {
    display:none;
  }
}

